<script setup lang="ts">
import delve from 'dlv'

const { find } = useStrapi()

const { data: employeesResponse } = await useCachedAsyncData('employees-response', () =>
  find('employees'),
)

const employees = delve(employeesResponse.value, 'data', '')

const currentIndex = ref(0)

const next = () => {
  currentIndex.value = (currentIndex.value + 1) % employees.length
}

const startSlider = () => {
  setInterval(() => {
    next()
  }, 2000)
}

onMounted(() => {
  startSlider()
})
</script>

<template>
  <div
    v-if="employees"
    class="team-slider-container"
  >
    <template
      v-for="(employee, index) in employees"
      :key="employee.id"
    >
      <div
        v-if="employee.attributes.picture.data"
        class="team-picture"
        :class="{ active: index === currentIndex }"
      >
        <NuxtImg
          loading="lazy"
          format="webp"
          :src="getStrapiMedia(employee.attributes.picture.data.attributes.url)"
          :alt="employee.attributes.name"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.team-slider-container {
  position: relative;
  overflow: hidden;
  width: 4rem;
  height: 4rem;

  .team-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    border-radius: 50px;

    &.active {
      opacity: 1;
    }

    img {
      max-width: 4rem;
    }
  }
}
</style>
