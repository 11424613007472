<script setup lang="ts">
import useIsDesktop from "~/composables/useIsDesktop";

const { isDesktop } = useIsDesktop()

const props = defineProps({
  isDefaultVersion: {
    type: Boolean,
    default: true,
  }
})
</script>

<template>
  <div class="header-contact">
    <div class="informations-container">
      <EmployeeSlider />
      <div class="informations">
        <span>{{ $t('header.contact.title') }}</span>
        <a
          class="phone"
          :href="'tel:' + $t('phone_link')"
          :title="$t('phone')"
        >{{ $t('phone') }}</a>
        <NuxtLinkLocale
          v-if="isDefaultVersion"
          class="text--color-grey-550"
          to="contact"
        >
          <UiIconsEnvelope />
          {{ $t('header.contact.link_label') }}
        </NuxtLinkLocale>
      </div>
    </div>
    <div
      v-if="isDefaultVersion || (!isDefaultVersion && isDesktop)"
      class="opening-hours"
    >
      <span>{{ $t('header.contact.opening_days') }}&nbsp;</span>
      <span>{{ $t('header.contact.opening_hours') }}</span>
      <span>{{ $t('header.contact.weekend_opening_hours') }}</span>
    </div>
  </div>
</template>

<style lang="scss">
  .header-contact {
    padding: $space-sm;
    display: flex;
    gap: $space-sm;
    flex-direction: column;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      font-size: 0.8rem;
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
      flex-direction: row;
      align-items: center;
      gap: 0;
      padding: 0;
    }

    @media (min-width: map-get($grid-breakpoints, xxl)) {
      font-size: 1rem;
    }

    .informations-container {
      display: flex;
      align-items: center;
      gap: $space-sm;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        > img {
          width: 25%
        }
      }

      .informations {
        > * {
          display: flex;
          align-items: center;
          gap: $space-xs;
          white-space: nowrap;
        }

        .phone {
          text-decoration: none;
          font-size: 1.4em;
          white-space: nowrap;
        }
      }
    }

    .opening-hours {
      color: var(--color-grey-550);

      @media (min-width: map-get($grid-breakpoints, xl)) {
        border-left : 1px solid var(--color-navy-30);
        padding-left: 1em;
        margin-left: 1em;
      }

      > * {
        &:last-child {
          display: block;
        }

        @media (min-width: map-get($grid-breakpoints, xl)) {
          display: block;
        }
      }
    }
  }
</style>
